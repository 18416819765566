/* @generated */
// prettier-ignore
if (Intl.DisplayNames && typeof Intl.DisplayNames.__addLocaleData === 'function') {
  Intl.DisplayNames.__addLocaleData({
  "data": {
    "patterns": {
      "locale": "{0} ({1})"
    },
    "types": {
      "calendar": {
        "long": {
          "buddhist": "Buddhist Calendar",
          "chinese": "Chinese Calendar",
          "coptic": "Coptic Calendar",
          "dangi": "Dangi Calendar",
          "ethiopic": "Ethiopic Calendar",
          "ethiopic-amete-alem": "Ethiopic Amete Alem Calendar",
          "gregorian": "Gregorian Calendar",
          "hebrew": "Hebrew Calendar",
          "indian": "Indian National Calendar",
          "islamic": "Islamic Calendar",
          "islamic-civil": "Islamic Calendar (tabular, civil epoch)",
          "islamic-rgsa": "Islamic Calendar (Saudi Arabia, sighting)",
          "islamic-tbla": "Islamic Calendar (tabular, astronomical epoch)",
          "islamic-umalqura": "Islamic Calendar (Umm al-Qura)",
          "iso8601": "ISO-8601 Calendar",
          "japanese": "Japanese Calendar",
          "persian": "Persian Calendar",
          "roc": "Minguo Calendar"
        },
        "narrow": {
        },
        "short": {
        }
      },
      "currency": {
        "long": {
          "ADP": "Andorran Peseta",
          "AED": "United Arab Emirates Dirham",
          "AFA": "Afghan Afghani (1927–2002)",
          "AFN": "Afghan Afghani",
          "ALK": "Albanian Lek (1946–1965)",
          "ALL": "Albanian Lek",
          "AMD": "Armenian Dram",
          "ANG": "Netherlands Antillean Guilder",
          "AOA": "Angolan Kwanza",
          "AOK": "Angolan Kwanza (1977–1991)",
          "AON": "Angolan New Kwanza (1990–2000)",
          "AOR": "Angolan Readjusted Kwanza (1995–1999)",
          "ARA": "Argentine Austral",
          "ARL": "Argentine Peso Ley (1970–1983)",
          "ARM": "Argentine Peso (1881–1970)",
          "ARP": "Argentine Peso (1983–1985)",
          "ARS": "Argentine Peso",
          "ATS": "Austrian Schilling",
          "AUD": "Australian Dollar",
          "AWG": "Aruban Florin",
          "AZM": "Azerbaijani Manat (1993–2006)",
          "AZN": "Azerbaijani Manat",
          "BAD": "Bosnia-Herzegovina Dinar (1992–1994)",
          "BAM": "Bosnia-Herzegovina Convertible Mark",
          "BAN": "Bosnia-Herzegovina New Dinar (1994–1997)",
          "BBD": "Barbadian Dollar",
          "BDT": "Bangladeshi Taka",
          "BEC": "Belgian Franc (convertible)",
          "BEF": "Belgian Franc",
          "BEL": "Belgian Franc (financial)",
          "BGL": "Bulgarian Hard Lev",
          "BGM": "Bulgarian Socialist Lev",
          "BGN": "Bulgarian Lev",
          "BGO": "Bulgarian Lev (1879–1952)",
          "BHD": "Bahraini Dinar",
          "BIF": "Burundian Franc",
          "BMD": "Bermudan Dollar",
          "BND": "Brunei Dollar",
          "BOB": "Bolivian Boliviano",
          "BOL": "Bolivian Boliviano (1863–1963)",
          "BOP": "Bolivian Peso",
          "BOV": "Bolivian Mvdol",
          "BRB": "Brazilian New Cruzeiro (1967–1986)",
          "BRC": "Brazilian Cruzado (1986–1989)",
          "BRE": "Brazilian Cruzeiro (1990–1993)",
          "BRL": "Brazilian Real",
          "BRN": "Brazilian New Cruzado (1989–1990)",
          "BRR": "Brazilian Cruzeiro (1993–1994)",
          "BRZ": "Brazilian Cruzeiro (1942–1967)",
          "BSD": "Bahamian Dollar",
          "BTN": "Bhutanese Ngultrum",
          "BUK": "Burmese Kyat",
          "BWP": "Botswanan Pula",
          "BYB": "Belarusian Ruble (1994–1999)",
          "BYN": "Belarusian Ruble",
          "BYR": "Belarusian Ruble (2000–2016)",
          "BZD": "Belize Dollar",
          "CAD": "Canadian Dollar",
          "CDF": "Congolese Franc",
          "CHE": "WIR Euro",
          "CHF": "Swiss Franc",
          "CHW": "WIR Franc",
          "CLE": "Chilean Escudo",
          "CLF": "Chilean Unit of Account (UF)",
          "CLP": "Chilean Peso",
          "CNH": "Chinese Yuan (offshore)",
          "CNX": "Chinese People’s Bank Dollar",
          "CNY": "Chinese Yuan",
          "COP": "Colombian Peso",
          "COU": "Colombian Real Value Unit",
          "CRC": "Costa Rican Colón",
          "CSD": "Serbian Dinar (2002–2006)",
          "CSK": "Czechoslovak Hard Koruna",
          "CUC": "Cuban Convertible Peso",
          "CUP": "Cuban Peso",
          "CVE": "Cape Verdean Escudo",
          "CYP": "Cypriot Pound",
          "CZK": "Czech Koruna",
          "DDM": "East German Mark",
          "DEM": "German Mark",
          "DJF": "Djiboutian Franc",
          "DKK": "Danish Krone",
          "DOP": "Dominican Peso",
          "DZD": "Algerian Dinar",
          "ECS": "Ecuadorian Sucre",
          "ECV": "Ecuadorian Unit of Constant Value",
          "EEK": "Estonian Kroon",
          "EGP": "Egyptian Pound",
          "ERN": "Eritrean Nakfa",
          "ESA": "Spanish Peseta (A account)",
          "ESB": "Spanish Peseta (convertible account)",
          "ESP": "Spanish Peseta",
          "ETB": "Ethiopian Birr",
          "EUR": "Euro",
          "FIM": "Finnish Markka",
          "FJD": "Fijian Dollar",
          "FKP": "Falkland Islands Pound",
          "FRF": "French Franc",
          "GBP": "British Pound",
          "GEK": "Georgian Kupon Larit",
          "GEL": "Georgian Lari",
          "GHC": "Ghanaian Cedi (1979–2007)",
          "GHS": "Ghanaian Cedi",
          "GIP": "Gibraltar Pound",
          "GMD": "Gambian Dalasi",
          "GNF": "Guinean Franc",
          "GNS": "Guinean Syli",
          "GQE": "Equatorial Guinean Ekwele",
          "GRD": "Greek Drachma",
          "GTQ": "Guatemalan Quetzal",
          "GWE": "Portuguese Guinea Escudo",
          "GWP": "Guinea-Bissau Peso",
          "GYD": "Guyanaese Dollar",
          "HKD": "Hong Kong Dollar",
          "HNL": "Honduran Lempira",
          "HRD": "Croatian Dinar",
          "HRK": "Croatian Kuna",
          "HTG": "Haitian Gourde",
          "HUF": "Hungarian Forint",
          "IDR": "Indonesian Rupiah",
          "IEP": "Irish Pound",
          "ILP": "Israeli Pound",
          "ILR": "Israeli Shekel (1980–1985)",
          "ILS": "Israeli New Shekel",
          "INR": "Indian Rupee",
          "IQD": "Iraqi Dinar",
          "IRR": "Iranian Rial",
          "ISJ": "Icelandic Króna (1918–1981)",
          "ISK": "Icelandic Króna",
          "ITL": "Italian Lira",
          "JMD": "Jamaican Dollar",
          "JOD": "Jordanian Dinar",
          "JPY": "Japanese Yen",
          "KES": "Kenyan Shilling",
          "KGS": "Kyrgystani Som",
          "KHR": "Cambodian Riel",
          "KMF": "Comorian Franc",
          "KPW": "North Korean Won",
          "KRH": "South Korean Hwan (1953–1962)",
          "KRO": "South Korean Won (1945–1953)",
          "KRW": "South Korean Won",
          "KWD": "Kuwaiti Dinar",
          "KYD": "Cayman Islands Dollar",
          "KZT": "Kazakhstani Tenge",
          "LAK": "Laotian Kip",
          "LBP": "Lebanese Pound",
          "LKR": "Sri Lankan Rupee",
          "LRD": "Liberian Dollar",
          "LSL": "Lesotho Loti",
          "LTL": "Lithuanian Litas",
          "LTT": "Lithuanian Talonas",
          "LUC": "Luxembourgian Convertible Franc",
          "LUF": "Luxembourgian Franc",
          "LUL": "Luxembourg Financial Franc",
          "LVL": "Latvian Lats",
          "LVR": "Latvian Ruble",
          "LYD": "Libyan Dinar",
          "MAD": "Moroccan Dirham",
          "MAF": "Moroccan Franc",
          "MCF": "Monegasque Franc",
          "MDC": "Moldovan Cupon",
          "MDL": "Moldovan Leu",
          "MGA": "Malagasy Ariary",
          "MGF": "Malagasy Franc",
          "MKD": "Macedonian Denar",
          "MKN": "Macedonian Denar (1992–1993)",
          "MLF": "Malian Franc",
          "MMK": "Myanmar Kyat",
          "MNT": "Mongolian Tugrik",
          "MOP": "Macanese Pataca",
          "MRO": "Mauritanian Ouguiya (1973–2017)",
          "MRU": "Mauritanian Ouguiya",
          "MTL": "Maltese Lira",
          "MTP": "Maltese Pound",
          "MUR": "Mauritian Rupee",
          "MVP": "Maldivian Rupee (1947–1981)",
          "MVR": "Maldivian Rufiyaa",
          "MWK": "Malawian Kwacha",
          "MXN": "Mexican Peso",
          "MXP": "Mexican Silver Peso (1861–1992)",
          "MXV": "Mexican Investment Unit",
          "MYR": "Malaysian Ringgit",
          "MZE": "Mozambican Escudo",
          "MZM": "Mozambican Metical (1980–2006)",
          "MZN": "Mozambican Metical",
          "NAD": "Namibian Dollar",
          "NGN": "Nigerian Naira",
          "NIC": "Nicaraguan Córdoba (1988–1991)",
          "NIO": "Nicaraguan Córdoba",
          "NLG": "Dutch Guilder",
          "NOK": "Norwegian Krone",
          "NPR": "Nepalese Rupee",
          "NZD": "New Zealand Dollar",
          "OMR": "Omani Rial",
          "PAB": "Panamanian Balboa",
          "PEI": "Peruvian Inti",
          "PEN": "Peruvian Sol",
          "PES": "Peruvian Sol (1863–1965)",
          "PGK": "Papua New Guinean Kina",
          "PHP": "Philippine Piso",
          "PKR": "Pakistani Rupee",
          "PLN": "Polish Zloty",
          "PLZ": "Polish Zloty (1950–1995)",
          "PTE": "Portuguese Escudo",
          "PYG": "Paraguayan Guarani",
          "QAR": "Qatari Rial",
          "RHD": "Rhodesian Dollar",
          "ROL": "Romanian Leu (1952–2006)",
          "RON": "Romanian Leu",
          "RSD": "Serbian Dinar",
          "RUB": "Russian Ruble",
          "RUR": "Russian Ruble (1991–1998)",
          "RWF": "Rwandan Franc",
          "SAR": "Saudi Riyal",
          "SBD": "Solomon Islands Dollar",
          "SCR": "Seychellois Rupee",
          "SDD": "Sudanese Dinar (1992–2007)",
          "SDG": "Sudanese Pound",
          "SDP": "Sudanese Pound (1957–1998)",
          "SEK": "Swedish Krona",
          "SGD": "Singapore Dollar",
          "SHP": "St. Helena Pound",
          "SIT": "Slovenian Tolar",
          "SKK": "Slovak Koruna",
          "SLL": "Sierra Leonean Leone",
          "SOS": "Somali Shilling",
          "SRD": "Surinamese Dollar",
          "SRG": "Surinamese Guilder",
          "SSP": "South Sudanese Pound",
          "STD": "São Tomé & Príncipe Dobra (1977–2017)",
          "STN": "São Tomé & Príncipe Dobra",
          "SUR": "Soviet Rouble",
          "SVC": "Salvadoran Colón",
          "SYP": "Syrian Pound",
          "SZL": "Swazi Lilangeni",
          "THB": "Thai Baht",
          "TJR": "Tajikistani Ruble",
          "TJS": "Tajikistani Somoni",
          "TMM": "Turkmenistani Manat (1993–2009)",
          "TMT": "Turkmenistani Manat",
          "TND": "Tunisian Dinar",
          "TOP": "Tongan Paʻanga",
          "TPE": "Timorese Escudo",
          "TRL": "Turkish Lira (1922–2005)",
          "TRY": "Turkish Lira",
          "TTD": "Trinidad & Tobago Dollar",
          "TWD": "New Taiwan Dollar",
          "TZS": "Tanzanian Shilling",
          "UAH": "Ukrainian Hryvnia",
          "UAK": "Ukrainian Karbovanets",
          "UGS": "Ugandan Shilling (1966–1987)",
          "UGX": "Ugandan Shilling",
          "USD": "US Dollar",
          "USN": "US Dollar (Next day)",
          "USS": "US Dollar (Same day)",
          "UYI": "Uruguayan Peso (Indexed Units)",
          "UYP": "Uruguayan Peso (1975–1993)",
          "UYU": "Uruguayan Peso",
          "UYW": "Uruguayan Nominal Wage Index Unit",
          "UZS": "Uzbekistani Som",
          "VEB": "Venezuelan Bolívar (1871–2008)",
          "VEF": "Venezuelan Bolívar (2008–2018)",
          "VES": "Venezuelan Bolívar",
          "VND": "Vietnamese Dong",
          "VNN": "Vietnamese Dong (1978–1985)",
          "VUV": "Vanuatu Vatu",
          "WST": "Samoan Tala",
          "XAF": "Central African CFA Franc",
          "XAG": "Silver",
          "XAU": "Gold",
          "XBA": "European Composite Unit",
          "XBB": "European Monetary Unit",
          "XBC": "European Unit of Account (XBC)",
          "XBD": "European Unit of Account (XBD)",
          "XCD": "East Caribbean Dollar",
          "XDR": "Special Drawing Rights",
          "XEU": "European Currency Unit",
          "XFO": "French Gold Franc",
          "XFU": "French UIC-Franc",
          "XOF": "West African CFA Franc",
          "XPD": "Palladium",
          "XPF": "CFP Franc",
          "XPT": "Platinum",
          "XRE": "RINET Funds",
          "XSU": "Sucre",
          "XTS": "Testing Currency Code",
          "XUA": "ADB Unit of Account",
          "XXX": "Unknown Currency",
          "YDD": "Yemeni Dinar",
          "YER": "Yemeni Rial",
          "YUD": "Yugoslavian Hard Dinar (1966–1990)",
          "YUM": "Yugoslavian New Dinar (1994–2002)",
          "YUN": "Yugoslavian Convertible Dinar (1990–1992)",
          "YUR": "Yugoslavian Reformed Dinar (1992–1993)",
          "ZAL": "South African Rand (financial)",
          "ZAR": "South African Rand",
          "ZMK": "Zambian Kwacha (1968–2012)",
          "ZMW": "Zambian Kwacha",
          "ZRN": "Zairean New Zaire (1993–1998)",
          "ZRZ": "Zairean Zaire (1971–1993)",
          "ZWD": "Zimbabwean Dollar (1980–2008)",
          "ZWL": "Zimbabwean Dollar (2009)",
          "ZWR": "Zimbabwean Dollar (2008)"
        },
        "narrow": {
        },
        "short": {
        }
      },
      "dateTimeField": {
        "long": {
          "day": "day",
          "dayOfYear": "day of year",
          "dayperiod": "AM/PM",
          "era": "era",
          "hour": "hour",
          "minute": "minute",
          "month": "month",
          "quarter": "quarter",
          "second": "second",
          "timeZoneName": "time zone",
          "weekOfMonth": "week of month",
          "weekOfYear": "week",
          "weekday": "day of the week",
          "weekdayOfMonth": "weekday of the month",
          "year": "year"
        },
        "narrow": {
          "day": "day",
          "dayOfYear": "day of yr.",
          "dayperiod": "AM/PM",
          "era": "era",
          "hour": "hr.",
          "minute": "min.",
          "month": "mo.",
          "quarter": "qtr.",
          "second": "sec.",
          "timeZoneName": "zone",
          "weekOfMonth": "wk. of mo.",
          "weekOfYear": "wk.",
          "weekday": "day of wk.",
          "weekdayOfMonth": "wkday. of mo.",
          "year": "yr."
        },
        "short": {
          "day": "day",
          "dayOfYear": "day of yr.",
          "dayperiod": "AM/PM",
          "era": "era",
          "hour": "hr.",
          "minute": "min.",
          "month": "mo.",
          "quarter": "qtr.",
          "second": "sec.",
          "timeZoneName": "zone",
          "weekOfMonth": "wk. of mo.",
          "weekOfYear": "wk.",
          "weekday": "day of wk.",
          "weekdayOfMonth": "wkday. of mo.",
          "year": "yr."
        }
      },
      "language": {
        "dialect": {
          "long": {
            "aa": "Afar",
            "ab": "Abkhazian",
            "ace": "Achinese",
            "ach": "Acoli",
            "ada": "Adangme",
            "ady": "Adyghe",
            "ae": "Avestan",
            "aeb": "Tunisian Arabic",
            "af": "Afrikaans",
            "afh": "Afrihili",
            "agq": "Aghem",
            "ain": "Ainu",
            "ak": "Akan",
            "akk": "Akkadian",
            "akz": "Alabama",
            "ale": "Aleut",
            "aln": "Gheg Albanian",
            "alt": "Southern Altai",
            "am": "Amharic",
            "an": "Aragonese",
            "ang": "Old English",
            "anp": "Angika",
            "ar": "Arabic",
            "ar-001": "Modern Standard Arabic",
            "arc": "Aramaic",
            "arn": "Mapuche",
            "aro": "Araona",
            "arp": "Arapaho",
            "arq": "Algerian Arabic",
            "ars": "Najdi Arabic",
            "arw": "Arawak",
            "ary": "Moroccan Arabic",
            "arz": "Egyptian Arabic",
            "as": "Assamese",
            "asa": "Asu",
            "ase": "American Sign Language",
            "ast": "Asturian",
            "av": "Avaric",
            "avk": "Kotava",
            "awa": "Awadhi",
            "ay": "Aymara",
            "az": "Azerbaijani",
            "ba": "Bashkir",
            "bal": "Baluchi",
            "ban": "Balinese",
            "bar": "Bavarian",
            "bas": "Basaa",
            "bax": "Bamun",
            "bbc": "Batak Toba",
            "bbj": "Ghomala",
            "be": "Belarusian",
            "bej": "Beja",
            "bem": "Bemba",
            "bew": "Betawi",
            "bez": "Bena",
            "bfd": "Bafut",
            "bfq": "Badaga",
            "bg": "Bulgarian",
            "bgn": "Western Balochi",
            "bho": "Bhojpuri",
            "bi": "Bislama",
            "bik": "Bikol",
            "bin": "Bini",
            "bjn": "Banjar",
            "bkm": "Kom",
            "bla": "Siksika",
            "bm": "Bambara",
            "bn": "Bangla",
            "bo": "Tibetan",
            "bpy": "Bishnupriya",
            "bqi": "Bakhtiari",
            "br": "Breton",
            "bra": "Braj",
            "brh": "Brahui",
            "brx": "Bodo",
            "bs": "Bosnian",
            "bss": "Akoose",
            "bua": "Buriat",
            "bug": "Buginese",
            "bum": "Bulu",
            "byn": "Blin",
            "byv": "Medumba",
            "ca": "Catalan",
            "cad": "Caddo",
            "car": "Carib",
            "cay": "Cayuga",
            "cch": "Atsam",
            "ccp": "Chakma",
            "ce": "Chechen",
            "ceb": "Cebuano",
            "cgg": "Chiga",
            "ch": "Chamorro",
            "chb": "Chibcha",
            "chg": "Chagatai",
            "chk": "Chuukese",
            "chm": "Mari",
            "chn": "Chinook Jargon",
            "cho": "Choctaw",
            "chp": "Chipewyan",
            "chr": "Cherokee",
            "chy": "Cheyenne",
            "cic": "Chickasaw",
            "ckb": "Central Kurdish",
            "co": "Corsican",
            "cop": "Coptic",
            "cps": "Capiznon",
            "cr": "Cree",
            "crh": "Crimean Turkish",
            "crs": "Seselwa Creole French",
            "cs": "Czech",
            "csb": "Kashubian",
            "cu": "Church Slavic",
            "cv": "Chuvash",
            "cy": "Welsh",
            "da": "Danish",
            "dak": "Dakota",
            "dar": "Dargwa",
            "dav": "Taita",
            "de": "German",
            "de-AT": "Austrian German",
            "de-CH": "Swiss High German",
            "del": "Delaware",
            "den": "Slave",
            "dgr": "Dogrib",
            "din": "Dinka",
            "dje": "Zarma",
            "doi": "Dogri",
            "dsb": "Lower Sorbian",
            "dtp": "Central Dusun",
            "dua": "Duala",
            "dum": "Middle Dutch",
            "dv": "Divehi",
            "dyo": "Jola-Fonyi",
            "dyu": "Dyula",
            "dz": "Dzongkha",
            "dzg": "Dazaga",
            "ebu": "Embu",
            "ee": "Ewe",
            "efi": "Efik",
            "egl": "Emilian",
            "egy": "Ancient Egyptian",
            "eka": "Ekajuk",
            "el": "Greek",
            "elx": "Elamite",
            "en": "English",
            "en-AU": "Australian English",
            "en-CA": "Canadian English",
            "en-GB": "British English",
            "en-US": "American English",
            "enm": "Middle English",
            "eo": "Esperanto",
            "es": "Spanish",
            "es-419": "Latin American Spanish",
            "es-ES": "European Spanish",
            "es-MX": "Mexican Spanish",
            "esu": "Central Yupik",
            "et": "Estonian",
            "eu": "Basque",
            "ewo": "Ewondo",
            "ext": "Extremaduran",
            "fa": "Persian",
            "fa-AF": "Dari",
            "fan": "Fang",
            "fat": "Fanti",
            "ff": "Fulah",
            "fi": "Finnish",
            "fil": "Filipino",
            "fit": "Tornedalen Finnish",
            "fj": "Fijian",
            "fo": "Faroese",
            "fon": "Fon",
            "fr": "French",
            "fr-CA": "Canadian French",
            "fr-CH": "Swiss French",
            "frc": "Cajun French",
            "frm": "Middle French",
            "fro": "Old French",
            "frp": "Arpitan",
            "frr": "Northern Frisian",
            "frs": "Eastern Frisian",
            "fur": "Friulian",
            "fy": "Western Frisian",
            "ga": "Irish",
            "gaa": "Ga",
            "gag": "Gagauz",
            "gan": "Gan Chinese",
            "gay": "Gayo",
            "gba": "Gbaya",
            "gbz": "Zoroastrian Dari",
            "gd": "Scottish Gaelic",
            "gez": "Geez",
            "gil": "Gilbertese",
            "gl": "Galician",
            "glk": "Gilaki",
            "gmh": "Middle High German",
            "gn": "Guarani",
            "goh": "Old High German",
            "gom": "Goan Konkani",
            "gon": "Gondi",
            "gor": "Gorontalo",
            "got": "Gothic",
            "grb": "Grebo",
            "grc": "Ancient Greek",
            "gsw": "Swiss German",
            "gu": "Gujarati",
            "guc": "Wayuu",
            "gur": "Frafra",
            "guz": "Gusii",
            "gv": "Manx",
            "gwi": "Gwichʼin",
            "ha": "Hausa",
            "hai": "Haida",
            "hak": "Hakka Chinese",
            "haw": "Hawaiian",
            "he": "Hebrew",
            "hi": "Hindi",
            "hif": "Fiji Hindi",
            "hil": "Hiligaynon",
            "hit": "Hittite",
            "hmn": "Hmong",
            "ho": "Hiri Motu",
            "hr": "Croatian",
            "hsb": "Upper Sorbian",
            "hsn": "Xiang Chinese",
            "ht": "Haitian Creole",
            "hu": "Hungarian",
            "hup": "Hupa",
            "hy": "Armenian",
            "hz": "Herero",
            "ia": "Interlingua",
            "iba": "Iban",
            "ibb": "Ibibio",
            "id": "Indonesian",
            "ie": "Interlingue",
            "ig": "Igbo",
            "ii": "Sichuan Yi",
            "ik": "Inupiaq",
            "ilo": "Iloko",
            "inh": "Ingush",
            "io": "Ido",
            "is": "Icelandic",
            "it": "Italian",
            "iu": "Inuktitut",
            "izh": "Ingrian",
            "ja": "Japanese",
            "jam": "Jamaican Creole English",
            "jbo": "Lojban",
            "jgo": "Ngomba",
            "jmc": "Machame",
            "jpr": "Judeo-Persian",
            "jrb": "Judeo-Arabic",
            "jut": "Jutish",
            "jv": "Javanese",
            "ka": "Georgian",
            "kaa": "Kara-Kalpak",
            "kab": "Kabyle",
            "kac": "Kachin",
            "kaj": "Jju",
            "kam": "Kamba",
            "kaw": "Kawi",
            "kbd": "Kabardian",
            "kbl": "Kanembu",
            "kcg": "Tyap",
            "kde": "Makonde",
            "kea": "Kabuverdianu",
            "ken": "Kenyang",
            "kfo": "Koro",
            "kg": "Kongo",
            "kgp": "Kaingang",
            "kha": "Khasi",
            "kho": "Khotanese",
            "khq": "Koyra Chiini",
            "khw": "Khowar",
            "ki": "Kikuyu",
            "kiu": "Kirmanjki",
            "kj": "Kuanyama",
            "kk": "Kazakh",
            "kkj": "Kako",
            "kl": "Kalaallisut",
            "kln": "Kalenjin",
            "km": "Khmer",
            "kmb": "Kimbundu",
            "kn": "Kannada",
            "ko": "Korean",
            "koi": "Komi-Permyak",
            "kok": "Konkani",
            "kos": "Kosraean",
            "kpe": "Kpelle",
            "kr": "Kanuri",
            "krc": "Karachay-Balkar",
            "kri": "Krio",
            "krj": "Kinaray-a",
            "krl": "Karelian",
            "kru": "Kurukh",
            "ks": "Kashmiri",
            "ksb": "Shambala",
            "ksf": "Bafia",
            "ksh": "Colognian",
            "ku": "Kurdish",
            "kum": "Kumyk",
            "kut": "Kutenai",
            "kv": "Komi",
            "kw": "Cornish",
            "ky": "Kyrgyz",
            "la": "Latin",
            "lad": "Ladino",
            "lag": "Langi",
            "lah": "Lahnda",
            "lam": "Lamba",
            "lb": "Luxembourgish",
            "lez": "Lezghian",
            "lfn": "Lingua Franca Nova",
            "lg": "Ganda",
            "li": "Limburgish",
            "lij": "Ligurian",
            "liv": "Livonian",
            "lkt": "Lakota",
            "lmo": "Lombard",
            "ln": "Lingala",
            "lo": "Lao",
            "lol": "Mongo",
            "lou": "Louisiana Creole",
            "loz": "Lozi",
            "lrc": "Northern Luri",
            "lt": "Lithuanian",
            "ltg": "Latgalian",
            "lu": "Luba-Katanga",
            "lua": "Luba-Lulua",
            "lui": "Luiseno",
            "lun": "Lunda",
            "luo": "Luo",
            "lus": "Mizo",
            "luy": "Luyia",
            "lv": "Latvian",
            "lzh": "Literary Chinese",
            "lzz": "Laz",
            "mad": "Madurese",
            "maf": "Mafa",
            "mag": "Magahi",
            "mai": "Maithili",
            "mak": "Makasar",
            "man": "Mandingo",
            "mas": "Masai",
            "mde": "Maba",
            "mdf": "Moksha",
            "mdr": "Mandar",
            "men": "Mende",
            "mer": "Meru",
            "mfe": "Morisyen",
            "mg": "Malagasy",
            "mga": "Middle Irish",
            "mgh": "Makhuwa-Meetto",
            "mgo": "Metaʼ",
            "mh": "Marshallese",
            "mi": "Māori",
            "mic": "Mi'kmaq",
            "min": "Minangkabau",
            "mk": "Macedonian",
            "ml": "Malayalam",
            "mn": "Mongolian",
            "mnc": "Manchu",
            "mni": "Manipuri",
            "moh": "Mohawk",
            "mos": "Mossi",
            "mr": "Marathi",
            "mrj": "Western Mari",
            "ms": "Malay",
            "mt": "Maltese",
            "mua": "Mundang",
            "mul": "Multiple languages",
            "mus": "Muscogee",
            "mwl": "Mirandese",
            "mwr": "Marwari",
            "mwv": "Mentawai",
            "my": "Burmese",
            "mye": "Myene",
            "myv": "Erzya",
            "mzn": "Mazanderani",
            "na": "Nauru",
            "nan": "Min Nan Chinese",
            "nap": "Neapolitan",
            "naq": "Nama",
            "nb": "Norwegian Bokmål",
            "nd": "North Ndebele",
            "nds": "Low German",
            "nds-NL": "Low Saxon",
            "ne": "Nepali",
            "new": "Newari",
            "ng": "Ndonga",
            "nia": "Nias",
            "niu": "Niuean",
            "njo": "Ao Naga",
            "nl": "Dutch",
            "nl-BE": "Flemish",
            "nmg": "Kwasio",
            "nn": "Norwegian Nynorsk",
            "nnh": "Ngiemboon",
            "no": "Norwegian",
            "nog": "Nogai",
            "non": "Old Norse",
            "nov": "Novial",
            "nqo": "N’Ko",
            "nr": "South Ndebele",
            "nso": "Northern Sotho",
            "nus": "Nuer",
            "nv": "Navajo",
            "nwc": "Classical Newari",
            "ny": "Nyanja",
            "nym": "Nyamwezi",
            "nyn": "Nyankole",
            "nyo": "Nyoro",
            "nzi": "Nzima",
            "oc": "Occitan",
            "oj": "Ojibwa",
            "om": "Oromo",
            "or": "Odia",
            "os": "Ossetic",
            "osa": "Osage",
            "ota": "Ottoman Turkish",
            "pa": "Punjabi",
            "pag": "Pangasinan",
            "pal": "Pahlavi",
            "pam": "Pampanga",
            "pap": "Papiamento",
            "pau": "Palauan",
            "pcd": "Picard",
            "pcm": "Nigerian Pidgin",
            "pdc": "Pennsylvania German",
            "pdt": "Plautdietsch",
            "peo": "Old Persian",
            "pfl": "Palatine German",
            "phn": "Phoenician",
            "pi": "Pali",
            "pl": "Polish",
            "pms": "Piedmontese",
            "pnt": "Pontic",
            "pon": "Pohnpeian",
            "prg": "Prussian",
            "pro": "Old Provençal",
            "ps": "Pashto",
            "pt": "Portuguese",
            "pt-BR": "Brazilian Portuguese",
            "pt-PT": "European Portuguese",
            "qu": "Quechua",
            "quc": "Kʼicheʼ",
            "qug": "Chimborazo Highland Quichua",
            "raj": "Rajasthani",
            "rap": "Rapanui",
            "rar": "Rarotongan",
            "rgn": "Romagnol",
            "rhg": "Rohingya",
            "rif": "Riffian",
            "rm": "Romansh",
            "rn": "Rundi",
            "ro": "Romanian",
            "ro-MD": "Moldavian",
            "rof": "Rombo",
            "rom": "Romany",
            "rtm": "Rotuman",
            "ru": "Russian",
            "rue": "Rusyn",
            "rug": "Roviana",
            "rup": "Aromanian",
            "rw": "Kinyarwanda",
            "rwk": "Rwa",
            "sa": "Sanskrit",
            "sad": "Sandawe",
            "sah": "Sakha",
            "sam": "Samaritan Aramaic",
            "saq": "Samburu",
            "sas": "Sasak",
            "sat": "Santali",
            "saz": "Saurashtra",
            "sba": "Ngambay",
            "sbp": "Sangu",
            "sc": "Sardinian",
            "scn": "Sicilian",
            "sco": "Scots",
            "sd": "Sindhi",
            "sdc": "Sassarese Sardinian",
            "sdh": "Southern Kurdish",
            "se": "Northern Sami",
            "see": "Seneca",
            "seh": "Sena",
            "sei": "Seri",
            "sel": "Selkup",
            "ses": "Koyraboro Senni",
            "sg": "Sango",
            "sga": "Old Irish",
            "sgs": "Samogitian",
            "sh": "Serbo-Croatian",
            "shi": "Tachelhit",
            "shn": "Shan",
            "shu": "Chadian Arabic",
            "si": "Sinhala",
            "sid": "Sidamo",
            "sk": "Slovak",
            "sl": "Slovenian",
            "sli": "Lower Silesian",
            "sly": "Selayar",
            "sm": "Samoan",
            "sma": "Southern Sami",
            "smj": "Lule Sami",
            "smn": "Inari Sami",
            "sms": "Skolt Sami",
            "sn": "Shona",
            "snk": "Soninke",
            "so": "Somali",
            "sog": "Sogdien",
            "sq": "Albanian",
            "sr": "Serbian",
            "sr-ME": "Montenegrin",
            "srn": "Sranan Tongo",
            "srr": "Serer",
            "ss": "Swati",
            "ssy": "Saho",
            "st": "Southern Sotho",
            "stq": "Saterland Frisian",
            "su": "Sundanese",
            "suk": "Sukuma",
            "sus": "Susu",
            "sux": "Sumerian",
            "sv": "Swedish",
            "sw": "Swahili",
            "sw-CD": "Congo Swahili",
            "swb": "Comorian",
            "syc": "Classical Syriac",
            "syr": "Syriac",
            "szl": "Silesian",
            "ta": "Tamil",
            "tcy": "Tulu",
            "te": "Telugu",
            "tem": "Timne",
            "teo": "Teso",
            "ter": "Tereno",
            "tet": "Tetum",
            "tg": "Tajik",
            "th": "Thai",
            "ti": "Tigrinya",
            "tig": "Tigre",
            "tiv": "Tiv",
            "tk": "Turkmen",
            "tkl": "Tokelau",
            "tkr": "Tsakhur",
            "tl": "Tagalog",
            "tlh": "Klingon",
            "tli": "Tlingit",
            "tly": "Talysh",
            "tmh": "Tamashek",
            "tn": "Tswana",
            "to": "Tongan",
            "tog": "Nyasa Tonga",
            "tpi": "Tok Pisin",
            "tr": "Turkish",
            "tru": "Turoyo",
            "trv": "Taroko",
            "ts": "Tsonga",
            "tsd": "Tsakonian",
            "tsi": "Tsimshian",
            "tt": "Tatar",
            "ttt": "Muslim Tat",
            "tum": "Tumbuka",
            "tvl": "Tuvalu",
            "tw": "Twi",
            "twq": "Tasawaq",
            "ty": "Tahitian",
            "tyv": "Tuvinian",
            "tzm": "Central Atlas Tamazight",
            "udm": "Udmurt",
            "ug": "Uyghur",
            "uga": "Ugaritic",
            "uk": "Ukrainian",
            "umb": "Umbundu",
            "und": "Unknown language",
            "ur": "Urdu",
            "uz": "Uzbek",
            "vai": "Vai",
            "ve": "Venda",
            "vec": "Venetian",
            "vep": "Veps",
            "vi": "Vietnamese",
            "vls": "West Flemish",
            "vmf": "Main-Franconian",
            "vo": "Volapük",
            "vot": "Votic",
            "vro": "Võro",
            "vun": "Vunjo",
            "wa": "Walloon",
            "wae": "Walser",
            "wal": "Wolaytta",
            "war": "Waray",
            "was": "Washo",
            "wbp": "Warlpiri",
            "wo": "Wolof",
            "wuu": "Wu Chinese",
            "xal": "Kalmyk",
            "xh": "Xhosa",
            "xmf": "Mingrelian",
            "xog": "Soga",
            "yao": "Yao",
            "yap": "Yapese",
            "yav": "Yangben",
            "ybb": "Yemba",
            "yi": "Yiddish",
            "yo": "Yoruba",
            "yrl": "Nheengatu",
            "yue": "Cantonese",
            "za": "Zhuang",
            "zap": "Zapotec",
            "zbl": "Blissymbols",
            "zea": "Zeelandic",
            "zen": "Zenaga",
            "zgh": "Standard Moroccan Tamazight",
            "zh": "Chinese",
            "zh-Hans": "Simplified Chinese",
            "zh-Hant": "Traditional Chinese",
            "zu": "Zulu",
            "zun": "Zuni",
            "zxx": "No linguistic content",
            "zza": "Zaza"
          },
          "narrow": {
          },
          "short": {
            "az": "Azeri",
            "en-GB": "UK English",
            "en-US": "US English"
          }
        },
        "standard": {
          "long": {
            "aa": "Afar",
            "ab": "Abkhazian",
            "ace": "Achinese",
            "ach": "Acoli",
            "ada": "Adangme",
            "ady": "Adyghe",
            "ae": "Avestan",
            "aeb": "Tunisian Arabic",
            "af": "Afrikaans",
            "afh": "Afrihili",
            "agq": "Aghem",
            "ain": "Ainu",
            "ak": "Akan",
            "akk": "Akkadian",
            "akz": "Alabama",
            "ale": "Aleut",
            "aln": "Gheg Albanian",
            "alt": "Southern Altai",
            "am": "Amharic",
            "an": "Aragonese",
            "ang": "Old English",
            "anp": "Angika",
            "ar": "Arabic",
            "ar-001": "Arabic (world)",
            "arc": "Aramaic",
            "arn": "Mapuche",
            "aro": "Araona",
            "arp": "Arapaho",
            "arq": "Algerian Arabic",
            "ars": "Najdi Arabic",
            "arw": "Arawak",
            "ary": "Moroccan Arabic",
            "arz": "Egyptian Arabic",
            "as": "Assamese",
            "asa": "Asu",
            "ase": "American Sign Language",
            "ast": "Asturian",
            "av": "Avaric",
            "avk": "Kotava",
            "awa": "Awadhi",
            "ay": "Aymara",
            "az": "Azerbaijani",
            "ba": "Bashkir",
            "bal": "Baluchi",
            "ban": "Balinese",
            "bar": "Bavarian",
            "bas": "Basaa",
            "bax": "Bamun",
            "bbc": "Batak Toba",
            "bbj": "Ghomala",
            "be": "Belarusian",
            "bej": "Beja",
            "bem": "Bemba",
            "bew": "Betawi",
            "bez": "Bena",
            "bfd": "Bafut",
            "bfq": "Badaga",
            "bg": "Bulgarian",
            "bgn": "Western Balochi",
            "bho": "Bhojpuri",
            "bi": "Bislama",
            "bik": "Bikol",
            "bin": "Bini",
            "bjn": "Banjar",
            "bkm": "Kom",
            "bla": "Siksika",
            "bm": "Bambara",
            "bn": "Bangla",
            "bo": "Tibetan",
            "bpy": "Bishnupriya",
            "bqi": "Bakhtiari",
            "br": "Breton",
            "bra": "Braj",
            "brh": "Brahui",
            "brx": "Bodo",
            "bs": "Bosnian",
            "bss": "Akoose",
            "bua": "Buriat",
            "bug": "Buginese",
            "bum": "Bulu",
            "byn": "Blin",
            "byv": "Medumba",
            "ca": "Catalan",
            "cad": "Caddo",
            "car": "Carib",
            "cay": "Cayuga",
            "cch": "Atsam",
            "ccp": "Chakma",
            "ce": "Chechen",
            "ceb": "Cebuano",
            "cgg": "Chiga",
            "ch": "Chamorro",
            "chb": "Chibcha",
            "chg": "Chagatai",
            "chk": "Chuukese",
            "chm": "Mari",
            "chn": "Chinook Jargon",
            "cho": "Choctaw",
            "chp": "Chipewyan",
            "chr": "Cherokee",
            "chy": "Cheyenne",
            "cic": "Chickasaw",
            "ckb": "Central Kurdish",
            "co": "Corsican",
            "cop": "Coptic",
            "cps": "Capiznon",
            "cr": "Cree",
            "crh": "Crimean Turkish",
            "crs": "Seselwa Creole French",
            "cs": "Czech",
            "csb": "Kashubian",
            "cu": "Church Slavic",
            "cv": "Chuvash",
            "cy": "Welsh",
            "da": "Danish",
            "dak": "Dakota",
            "dar": "Dargwa",
            "dav": "Taita",
            "de": "German",
            "de-AT": "German (Austria)",
            "de-CH": "German (Switzerland)",
            "del": "Delaware",
            "den": "Slave",
            "dgr": "Dogrib",
            "din": "Dinka",
            "dje": "Zarma",
            "doi": "Dogri",
            "dsb": "Lower Sorbian",
            "dtp": "Central Dusun",
            "dua": "Duala",
            "dum": "Middle Dutch",
            "dv": "Divehi",
            "dyo": "Jola-Fonyi",
            "dyu": "Dyula",
            "dz": "Dzongkha",
            "dzg": "Dazaga",
            "ebu": "Embu",
            "ee": "Ewe",
            "efi": "Efik",
            "egl": "Emilian",
            "egy": "Ancient Egyptian",
            "eka": "Ekajuk",
            "el": "Greek",
            "elx": "Elamite",
            "en": "English",
            "en-AU": "English (Australia)",
            "en-CA": "English (Canada)",
            "en-GB": "English (United Kingdom)",
            "en-US": "English (United States)",
            "enm": "Middle English",
            "eo": "Esperanto",
            "es": "Spanish",
            "es-419": "Spanish (Latin America)",
            "es-ES": "Spanish (Spain)",
            "es-MX": "Spanish (Mexico)",
            "esu": "Central Yupik",
            "et": "Estonian",
            "eu": "Basque",
            "ewo": "Ewondo",
            "ext": "Extremaduran",
            "fa": "Persian",
            "fa-AF": "Persian (Afghanistan)",
            "fan": "Fang",
            "fat": "Fanti",
            "ff": "Fulah",
            "fi": "Finnish",
            "fil": "Filipino",
            "fit": "Tornedalen Finnish",
            "fj": "Fijian",
            "fo": "Faroese",
            "fon": "Fon",
            "fr": "French",
            "fr-CA": "French (Canada)",
            "fr-CH": "French (Switzerland)",
            "frc": "Cajun French",
            "frm": "Middle French",
            "fro": "Old French",
            "frp": "Arpitan",
            "frr": "Northern Frisian",
            "frs": "Eastern Frisian",
            "fur": "Friulian",
            "fy": "Western Frisian",
            "ga": "Irish",
            "gaa": "Ga",
            "gag": "Gagauz",
            "gan": "Gan Chinese",
            "gay": "Gayo",
            "gba": "Gbaya",
            "gbz": "Zoroastrian Dari",
            "gd": "Scottish Gaelic",
            "gez": "Geez",
            "gil": "Gilbertese",
            "gl": "Galician",
            "glk": "Gilaki",
            "gmh": "Middle High German",
            "gn": "Guarani",
            "goh": "Old High German",
            "gom": "Goan Konkani",
            "gon": "Gondi",
            "gor": "Gorontalo",
            "got": "Gothic",
            "grb": "Grebo",
            "grc": "Ancient Greek",
            "gsw": "Swiss German",
            "gu": "Gujarati",
            "guc": "Wayuu",
            "gur": "Frafra",
            "guz": "Gusii",
            "gv": "Manx",
            "gwi": "Gwichʼin",
            "ha": "Hausa",
            "hai": "Haida",
            "hak": "Hakka Chinese",
            "haw": "Hawaiian",
            "he": "Hebrew",
            "hi": "Hindi",
            "hif": "Fiji Hindi",
            "hil": "Hiligaynon",
            "hit": "Hittite",
            "hmn": "Hmong",
            "ho": "Hiri Motu",
            "hr": "Croatian",
            "hsb": "Upper Sorbian",
            "hsn": "Xiang Chinese",
            "ht": "Haitian Creole",
            "hu": "Hungarian",
            "hup": "Hupa",
            "hy": "Armenian",
            "hz": "Herero",
            "ia": "Interlingua",
            "iba": "Iban",
            "ibb": "Ibibio",
            "id": "Indonesian",
            "ie": "Interlingue",
            "ig": "Igbo",
            "ii": "Sichuan Yi",
            "ik": "Inupiaq",
            "ilo": "Iloko",
            "inh": "Ingush",
            "io": "Ido",
            "is": "Icelandic",
            "it": "Italian",
            "iu": "Inuktitut",
            "izh": "Ingrian",
            "ja": "Japanese",
            "jam": "Jamaican Creole English",
            "jbo": "Lojban",
            "jgo": "Ngomba",
            "jmc": "Machame",
            "jpr": "Judeo-Persian",
            "jrb": "Judeo-Arabic",
            "jut": "Jutish",
            "jv": "Javanese",
            "ka": "Georgian",
            "kaa": "Kara-Kalpak",
            "kab": "Kabyle",
            "kac": "Kachin",
            "kaj": "Jju",
            "kam": "Kamba",
            "kaw": "Kawi",
            "kbd": "Kabardian",
            "kbl": "Kanembu",
            "kcg": "Tyap",
            "kde": "Makonde",
            "kea": "Kabuverdianu",
            "ken": "Kenyang",
            "kfo": "Koro",
            "kg": "Kongo",
            "kgp": "Kaingang",
            "kha": "Khasi",
            "kho": "Khotanese",
            "khq": "Koyra Chiini",
            "khw": "Khowar",
            "ki": "Kikuyu",
            "kiu": "Kirmanjki",
            "kj": "Kuanyama",
            "kk": "Kazakh",
            "kkj": "Kako",
            "kl": "Kalaallisut",
            "kln": "Kalenjin",
            "km": "Khmer",
            "kmb": "Kimbundu",
            "kn": "Kannada",
            "ko": "Korean",
            "koi": "Komi-Permyak",
            "kok": "Konkani",
            "kos": "Kosraean",
            "kpe": "Kpelle",
            "kr": "Kanuri",
            "krc": "Karachay-Balkar",
            "kri": "Krio",
            "krj": "Kinaray-a",
            "krl": "Karelian",
            "kru": "Kurukh",
            "ks": "Kashmiri",
            "ksb": "Shambala",
            "ksf": "Bafia",
            "ksh": "Colognian",
            "ku": "Kurdish",
            "kum": "Kumyk",
            "kut": "Kutenai",
            "kv": "Komi",
            "kw": "Cornish",
            "ky": "Kyrgyz",
            "la": "Latin",
            "lad": "Ladino",
            "lag": "Langi",
            "lah": "Lahnda",
            "lam": "Lamba",
            "lb": "Luxembourgish",
            "lez": "Lezghian",
            "lfn": "Lingua Franca Nova",
            "lg": "Ganda",
            "li": "Limburgish",
            "lij": "Ligurian",
            "liv": "Livonian",
            "lkt": "Lakota",
            "lmo": "Lombard",
            "ln": "Lingala",
            "lo": "Lao",
            "lol": "Mongo",
            "lou": "Louisiana Creole",
            "loz": "Lozi",
            "lrc": "Northern Luri",
            "lt": "Lithuanian",
            "ltg": "Latgalian",
            "lu": "Luba-Katanga",
            "lua": "Luba-Lulua",
            "lui": "Luiseno",
            "lun": "Lunda",
            "luo": "Luo",
            "lus": "Mizo",
            "luy": "Luyia",
            "lv": "Latvian",
            "lzh": "Literary Chinese",
            "lzz": "Laz",
            "mad": "Madurese",
            "maf": "Mafa",
            "mag": "Magahi",
            "mai": "Maithili",
            "mak": "Makasar",
            "man": "Mandingo",
            "mas": "Masai",
            "mde": "Maba",
            "mdf": "Moksha",
            "mdr": "Mandar",
            "men": "Mende",
            "mer": "Meru",
            "mfe": "Morisyen",
            "mg": "Malagasy",
            "mga": "Middle Irish",
            "mgh": "Makhuwa-Meetto",
            "mgo": "Metaʼ",
            "mh": "Marshallese",
            "mi": "Māori",
            "mic": "Mi'kmaq",
            "min": "Minangkabau",
            "mk": "Macedonian",
            "ml": "Malayalam",
            "mn": "Mongolian",
            "mnc": "Manchu",
            "mni": "Manipuri",
            "moh": "Mohawk",
            "mos": "Mossi",
            "mr": "Marathi",
            "mrj": "Western Mari",
            "ms": "Malay",
            "mt": "Maltese",
            "mua": "Mundang",
            "mul": "Multiple languages",
            "mus": "Muscogee",
            "mwl": "Mirandese",
            "mwr": "Marwari",
            "mwv": "Mentawai",
            "my": "Burmese",
            "mye": "Myene",
            "myv": "Erzya",
            "mzn": "Mazanderani",
            "na": "Nauru",
            "nan": "Min Nan Chinese",
            "nap": "Neapolitan",
            "naq": "Nama",
            "nb": "Norwegian Bokmål",
            "nd": "North Ndebele",
            "nds": "Low German",
            "nds-NL": "Low German (Netherlands)",
            "ne": "Nepali",
            "new": "Newari",
            "ng": "Ndonga",
            "nia": "Nias",
            "niu": "Niuean",
            "njo": "Ao Naga",
            "nl": "Dutch",
            "nl-BE": "Dutch (Belgium)",
            "nmg": "Kwasio",
            "nn": "Norwegian Nynorsk",
            "nnh": "Ngiemboon",
            "no": "Norwegian",
            "nog": "Nogai",
            "non": "Old Norse",
            "nov": "Novial",
            "nqo": "N’Ko",
            "nr": "South Ndebele",
            "nso": "Northern Sotho",
            "nus": "Nuer",
            "nv": "Navajo",
            "nwc": "Classical Newari",
            "ny": "Nyanja",
            "nym": "Nyamwezi",
            "nyn": "Nyankole",
            "nyo": "Nyoro",
            "nzi": "Nzima",
            "oc": "Occitan",
            "oj": "Ojibwa",
            "om": "Oromo",
            "or": "Odia",
            "os": "Ossetic",
            "osa": "Osage",
            "ota": "Ottoman Turkish",
            "pa": "Punjabi",
            "pag": "Pangasinan",
            "pal": "Pahlavi",
            "pam": "Pampanga",
            "pap": "Papiamento",
            "pau": "Palauan",
            "pcd": "Picard",
            "pcm": "Nigerian Pidgin",
            "pdc": "Pennsylvania German",
            "pdt": "Plautdietsch",
            "peo": "Old Persian",
            "pfl": "Palatine German",
            "phn": "Phoenician",
            "pi": "Pali",
            "pl": "Polish",
            "pms": "Piedmontese",
            "pnt": "Pontic",
            "pon": "Pohnpeian",
            "prg": "Prussian",
            "pro": "Old Provençal",
            "ps": "Pashto",
            "pt": "Portuguese",
            "pt-BR": "Portuguese (Brazil)",
            "pt-PT": "Portuguese (Portugal)",
            "qu": "Quechua",
            "quc": "Kʼicheʼ",
            "qug": "Chimborazo Highland Quichua",
            "raj": "Rajasthani",
            "rap": "Rapanui",
            "rar": "Rarotongan",
            "rgn": "Romagnol",
            "rhg": "Rohingya",
            "rif": "Riffian",
            "rm": "Romansh",
            "rn": "Rundi",
            "ro": "Romanian",
            "ro-MD": "Romanian (Moldova)",
            "rof": "Rombo",
            "rom": "Romany",
            "rtm": "Rotuman",
            "ru": "Russian",
            "rue": "Rusyn",
            "rug": "Roviana",
            "rup": "Aromanian",
            "rw": "Kinyarwanda",
            "rwk": "Rwa",
            "sa": "Sanskrit",
            "sad": "Sandawe",
            "sah": "Sakha",
            "sam": "Samaritan Aramaic",
            "saq": "Samburu",
            "sas": "Sasak",
            "sat": "Santali",
            "saz": "Saurashtra",
            "sba": "Ngambay",
            "sbp": "Sangu",
            "sc": "Sardinian",
            "scn": "Sicilian",
            "sco": "Scots",
            "sd": "Sindhi",
            "sdc": "Sassarese Sardinian",
            "sdh": "Southern Kurdish",
            "se": "Northern Sami",
            "see": "Seneca",
            "seh": "Sena",
            "sei": "Seri",
            "sel": "Selkup",
            "ses": "Koyraboro Senni",
            "sg": "Sango",
            "sga": "Old Irish",
            "sgs": "Samogitian",
            "sh": "Serbo-Croatian",
            "shi": "Tachelhit",
            "shn": "Shan",
            "shu": "Chadian Arabic",
            "si": "Sinhala",
            "sid": "Sidamo",
            "sk": "Slovak",
            "sl": "Slovenian",
            "sli": "Lower Silesian",
            "sly": "Selayar",
            "sm": "Samoan",
            "sma": "Southern Sami",
            "smj": "Lule Sami",
            "smn": "Inari Sami",
            "sms": "Skolt Sami",
            "sn": "Shona",
            "snk": "Soninke",
            "so": "Somali",
            "sog": "Sogdien",
            "sq": "Albanian",
            "sr": "Serbian",
            "sr-ME": "Serbian (Montenegro)",
            "srn": "Sranan Tongo",
            "srr": "Serer",
            "ss": "Swati",
            "ssy": "Saho",
            "st": "Southern Sotho",
            "stq": "Saterland Frisian",
            "su": "Sundanese",
            "suk": "Sukuma",
            "sus": "Susu",
            "sux": "Sumerian",
            "sv": "Swedish",
            "sw": "Swahili",
            "sw-CD": "Swahili (Congo - Kinshasa)",
            "swb": "Comorian",
            "syc": "Classical Syriac",
            "syr": "Syriac",
            "szl": "Silesian",
            "ta": "Tamil",
            "tcy": "Tulu",
            "te": "Telugu",
            "tem": "Timne",
            "teo": "Teso",
            "ter": "Tereno",
            "tet": "Tetum",
            "tg": "Tajik",
            "th": "Thai",
            "ti": "Tigrinya",
            "tig": "Tigre",
            "tiv": "Tiv",
            "tk": "Turkmen",
            "tkl": "Tokelau",
            "tkr": "Tsakhur",
            "tl": "Tagalog",
            "tlh": "Klingon",
            "tli": "Tlingit",
            "tly": "Talysh",
            "tmh": "Tamashek",
            "tn": "Tswana",
            "to": "Tongan",
            "tog": "Nyasa Tonga",
            "tpi": "Tok Pisin",
            "tr": "Turkish",
            "tru": "Turoyo",
            "trv": "Taroko",
            "ts": "Tsonga",
            "tsd": "Tsakonian",
            "tsi": "Tsimshian",
            "tt": "Tatar",
            "ttt": "Muslim Tat",
            "tum": "Tumbuka",
            "tvl": "Tuvalu",
            "tw": "Twi",
            "twq": "Tasawaq",
            "ty": "Tahitian",
            "tyv": "Tuvinian",
            "tzm": "Central Atlas Tamazight",
            "udm": "Udmurt",
            "ug": "Uyghur",
            "uga": "Ugaritic",
            "uk": "Ukrainian",
            "umb": "Umbundu",
            "und": "Unknown language",
            "ur": "Urdu",
            "uz": "Uzbek",
            "vai": "Vai",
            "ve": "Venda",
            "vec": "Venetian",
            "vep": "Veps",
            "vi": "Vietnamese",
            "vls": "West Flemish",
            "vmf": "Main-Franconian",
            "vo": "Volapük",
            "vot": "Votic",
            "vro": "Võro",
            "vun": "Vunjo",
            "wa": "Walloon",
            "wae": "Walser",
            "wal": "Wolaytta",
            "war": "Waray",
            "was": "Washo",
            "wbp": "Warlpiri",
            "wo": "Wolof",
            "wuu": "Wu Chinese",
            "xal": "Kalmyk",
            "xh": "Xhosa",
            "xmf": "Mingrelian",
            "xog": "Soga",
            "yao": "Yao",
            "yap": "Yapese",
            "yav": "Yangben",
            "ybb": "Yemba",
            "yi": "Yiddish",
            "yo": "Yoruba",
            "yrl": "Nheengatu",
            "yue": "Cantonese",
            "za": "Zhuang",
            "zap": "Zapotec",
            "zbl": "Blissymbols",
            "zea": "Zeelandic",
            "zen": "Zenaga",
            "zgh": "Standard Moroccan Tamazight",
            "zh": "Chinese",
            "zh-Hans": "Simplified Chinese",
            "zh-Hant": "Traditional Chinese",
            "zu": "Zulu",
            "zun": "Zuni",
            "zxx": "No linguistic content",
            "zza": "Zaza"
          },
          "narrow": {
          },
          "short": {
            "az": "Azeri",
            "en-GB": "English (UK)",
            "en-US": "English (US)"
          }
        }
      },
      "region": {
        "long": {
          "001": "world",
          "002": "Africa",
          "003": "North America",
          "005": "South America",
          "009": "Oceania",
          "011": "Western Africa",
          "013": "Central America",
          "014": "Eastern Africa",
          "015": "Northern Africa",
          "017": "Middle Africa",
          "018": "Southern Africa",
          "019": "Americas",
          "021": "Northern America",
          "029": "Caribbean",
          "030": "Eastern Asia",
          "034": "Southern Asia",
          "035": "Southeast Asia",
          "039": "Southern Europe",
          "053": "Australasia",
          "054": "Melanesia",
          "057": "Micronesian Region",
          "061": "Polynesia",
          "142": "Asia",
          "143": "Central Asia",
          "145": "Western Asia",
          "150": "Europe",
          "151": "Eastern Europe",
          "154": "Northern Europe",
          "155": "Western Europe",
          "202": "Sub-Saharan Africa",
          "419": "Latin America",
          "AC": "Ascension Island",
          "AD": "Andorra",
          "AE": "United Arab Emirates",
          "AF": "Afghanistan",
          "AG": "Antigua & Barbuda",
          "AI": "Anguilla",
          "AL": "Albania",
          "AM": "Armenia",
          "AO": "Angola",
          "AQ": "Antarctica",
          "AR": "Argentina",
          "AS": "American Samoa",
          "AT": "Austria",
          "AU": "Australia",
          "AW": "Aruba",
          "AX": "Åland Islands",
          "AZ": "Azerbaijan",
          "BA": "Bosnia & Herzegovina",
          "BB": "Barbados",
          "BD": "Bangladesh",
          "BE": "Belgium",
          "BF": "Burkina Faso",
          "BG": "Bulgaria",
          "BH": "Bahrain",
          "BI": "Burundi",
          "BJ": "Benin",
          "BL": "St. Barthélemy",
          "BM": "Bermuda",
          "BN": "Brunei",
          "BO": "Bolivia",
          "BQ": "Caribbean Netherlands",
          "BR": "Brazil",
          "BS": "Bahamas",
          "BT": "Bhutan",
          "BV": "Bouvet Island",
          "BW": "Botswana",
          "BY": "Belarus",
          "BZ": "Belize",
          "CA": "Canada",
          "CC": "Cocos (Keeling) Islands",
          "CD": "Congo - Kinshasa",
          "CF": "Central African Republic",
          "CG": "Congo - Brazzaville",
          "CH": "Switzerland",
          "CI": "Côte d’Ivoire",
          "CK": "Cook Islands",
          "CL": "Chile",
          "CM": "Cameroon",
          "CN": "China",
          "CO": "Colombia",
          "CP": "Clipperton Island",
          "CR": "Costa Rica",
          "CU": "Cuba",
          "CV": "Cape Verde",
          "CW": "Curaçao",
          "CX": "Christmas Island",
          "CY": "Cyprus",
          "CZ": "Czechia",
          "DE": "Germany",
          "DG": "Diego Garcia",
          "DJ": "Djibouti",
          "DK": "Denmark",
          "DM": "Dominica",
          "DO": "Dominican Republic",
          "DZ": "Algeria",
          "EA": "Ceuta & Melilla",
          "EC": "Ecuador",
          "EE": "Estonia",
          "EG": "Egypt",
          "EH": "Western Sahara",
          "ER": "Eritrea",
          "ES": "Spain",
          "ET": "Ethiopia",
          "EU": "European Union",
          "EZ": "Eurozone",
          "FI": "Finland",
          "FJ": "Fiji",
          "FK": "Falkland Islands",
          "FM": "Micronesia",
          "FO": "Faroe Islands",
          "FR": "France",
          "GA": "Gabon",
          "GB": "United Kingdom",
          "GD": "Grenada",
          "GE": "Georgia",
          "GF": "French Guiana",
          "GG": "Guernsey",
          "GH": "Ghana",
          "GI": "Gibraltar",
          "GL": "Greenland",
          "GM": "Gambia",
          "GN": "Guinea",
          "GP": "Guadeloupe",
          "GQ": "Equatorial Guinea",
          "GR": "Greece",
          "GS": "South Georgia & South Sandwich Islands",
          "GT": "Guatemala",
          "GU": "Guam",
          "GW": "Guinea-Bissau",
          "GY": "Guyana",
          "HK": "Hong Kong SAR China",
          "HM": "Heard & McDonald Islands",
          "HN": "Honduras",
          "HR": "Croatia",
          "HT": "Haiti",
          "HU": "Hungary",
          "IC": "Canary Islands",
          "ID": "Indonesia",
          "IE": "Ireland",
          "IL": "Israel",
          "IM": "Isle of Man",
          "IN": "India",
          "IO": "British Indian Ocean Territory",
          "IQ": "Iraq",
          "IR": "Iran",
          "IS": "Iceland",
          "IT": "Italy",
          "JE": "Jersey",
          "JM": "Jamaica",
          "JO": "Jordan",
          "JP": "Japan",
          "KE": "Kenya",
          "KG": "Kyrgyzstan",
          "KH": "Cambodia",
          "KI": "Kiribati",
          "KM": "Comoros",
          "KN": "St. Kitts & Nevis",
          "KP": "North Korea",
          "KR": "South Korea",
          "KW": "Kuwait",
          "KY": "Cayman Islands",
          "KZ": "Kazakhstan",
          "LA": "Laos",
          "LB": "Lebanon",
          "LC": "St. Lucia",
          "LI": "Liechtenstein",
          "LK": "Sri Lanka",
          "LR": "Liberia",
          "LS": "Lesotho",
          "LT": "Lithuania",
          "LU": "Luxembourg",
          "LV": "Latvia",
          "LY": "Libya",
          "MA": "Morocco",
          "MC": "Monaco",
          "MD": "Moldova",
          "ME": "Montenegro",
          "MF": "St. Martin",
          "MG": "Madagascar",
          "MH": "Marshall Islands",
          "MK": "North Macedonia",
          "ML": "Mali",
          "MM": "Myanmar (Burma)",
          "MN": "Mongolia",
          "MO": "Macao SAR China",
          "MP": "Northern Mariana Islands",
          "MQ": "Martinique",
          "MR": "Mauritania",
          "MS": "Montserrat",
          "MT": "Malta",
          "MU": "Mauritius",
          "MV": "Maldives",
          "MW": "Malawi",
          "MX": "Mexico",
          "MY": "Malaysia",
          "MZ": "Mozambique",
          "NA": "Namibia",
          "NC": "New Caledonia",
          "NE": "Niger",
          "NF": "Norfolk Island",
          "NG": "Nigeria",
          "NI": "Nicaragua",
          "NL": "Netherlands",
          "NO": "Norway",
          "NP": "Nepal",
          "NR": "Nauru",
          "NU": "Niue",
          "NZ": "New Zealand",
          "OM": "Oman",
          "PA": "Panama",
          "PE": "Peru",
          "PF": "French Polynesia",
          "PG": "Papua New Guinea",
          "PH": "Philippines",
          "PK": "Pakistan",
          "PL": "Poland",
          "PM": "St. Pierre & Miquelon",
          "PN": "Pitcairn Islands",
          "PR": "Puerto Rico",
          "PS": "Palestinian Territories",
          "PT": "Portugal",
          "PW": "Palau",
          "PY": "Paraguay",
          "QA": "Qatar",
          "QO": "Outlying Oceania",
          "RE": "Réunion",
          "RO": "Romania",
          "RS": "Serbia",
          "RU": "Russia",
          "RW": "Rwanda",
          "SA": "Saudi Arabia",
          "SB": "Solomon Islands",
          "SC": "Seychelles",
          "SD": "Sudan",
          "SE": "Sweden",
          "SG": "Singapore",
          "SH": "St. Helena",
          "SI": "Slovenia",
          "SJ": "Svalbard & Jan Mayen",
          "SK": "Slovakia",
          "SL": "Sierra Leone",
          "SM": "San Marino",
          "SN": "Senegal",
          "SO": "Somalia",
          "SR": "Suriname",
          "SS": "South Sudan",
          "ST": "São Tomé & Príncipe",
          "SV": "El Salvador",
          "SX": "Sint Maarten",
          "SY": "Syria",
          "SZ": "Eswatini",
          "TA": "Tristan da Cunha",
          "TC": "Turks & Caicos Islands",
          "TD": "Chad",
          "TF": "French Southern Territories",
          "TG": "Togo",
          "TH": "Thailand",
          "TJ": "Tajikistan",
          "TK": "Tokelau",
          "TL": "Timor-Leste",
          "TM": "Turkmenistan",
          "TN": "Tunisia",
          "TO": "Tonga",
          "TR": "Turkey",
          "TT": "Trinidad & Tobago",
          "TV": "Tuvalu",
          "TW": "Taiwan",
          "TZ": "Tanzania",
          "UA": "Ukraine",
          "UG": "Uganda",
          "UM": "U.S. Outlying Islands",
          "UN": "United Nations",
          "US": "United States",
          "UY": "Uruguay",
          "UZ": "Uzbekistan",
          "VA": "Vatican City",
          "VC": "St. Vincent & Grenadines",
          "VE": "Venezuela",
          "VG": "British Virgin Islands",
          "VI": "U.S. Virgin Islands",
          "VN": "Vietnam",
          "VU": "Vanuatu",
          "WF": "Wallis & Futuna",
          "WS": "Samoa",
          "XA": "Pseudo-Accents",
          "XB": "Pseudo-Bidi",
          "XK": "Kosovo",
          "YE": "Yemen",
          "YT": "Mayotte",
          "ZA": "South Africa",
          "ZM": "Zambia",
          "ZW": "Zimbabwe",
          "ZZ": "Unknown Region"
        },
        "narrow": {
        },
        "short": {
          "BA": "Bosnia",
          "GB": "UK",
          "HK": "Hong Kong",
          "MM": "Myanmar",
          "MO": "Macao",
          "PS": "Palestine",
          "UN": "UN",
          "US": "US"
        }
      },
      "script": {
        "long": {
          "Adlm": "Adlam",
          "Afak": "Afaka",
          "Aghb": "Caucasian Albanian",
          "Ahom": "Ahom",
          "Arab": "Arabic",
          "Aran": "Nastaliq",
          "Armi": "Imperial Aramaic",
          "Armn": "Armenian",
          "Avst": "Avestan",
          "Bali": "Balinese",
          "Bamu": "Bamum",
          "Bass": "Bassa Vah",
          "Batk": "Batak",
          "Beng": "Bangla",
          "Bhks": "Bhaiksuki",
          "Blis": "Blissymbols",
          "Bopo": "Bopomofo",
          "Brah": "Brahmi",
          "Brai": "Braille",
          "Bugi": "Buginese",
          "Buhd": "Buhid",
          "Cakm": "Chakma",
          "Cans": "Unified Canadian Aboriginal Syllabics",
          "Cari": "Carian",
          "Cham": "Cham",
          "Cher": "Cherokee",
          "Chrs": "Chorasmian",
          "Cirt": "Cirth",
          "Copt": "Coptic",
          "Cpmn": "Cypro-Minoan",
          "Cprt": "Cypriot",
          "Cyrl": "Cyrillic",
          "Cyrs": "Old Church Slavonic Cyrillic",
          "Deva": "Devanagari",
          "Diak": "Dives Akuru",
          "Dogr": "Dogra",
          "Dsrt": "Deseret",
          "Dupl": "Duployan shorthand",
          "Egyd": "Egyptian demotic",
          "Egyh": "Egyptian hieratic",
          "Egyp": "Egyptian hieroglyphs",
          "Elba": "Elbasan",
          "Elym": "Elymaic",
          "Ethi": "Ethiopic",
          "Geok": "Georgian Khutsuri",
          "Geor": "Georgian",
          "Glag": "Glagolitic",
          "Gong": "Gunjala Gondi",
          "Gonm": "Masaram Gondi",
          "Goth": "Gothic",
          "Gran": "Grantha",
          "Grek": "Greek",
          "Gujr": "Gujarati",
          "Guru": "Gurmukhi",
          "Hanb": "Han with Bopomofo",
          "Hang": "Hangul",
          "Hani": "Han",
          "Hano": "Hanunoo",
          "Hans": "Simplified",
          "Hant": "Traditional",
          "Hatr": "Hatran",
          "Hebr": "Hebrew",
          "Hira": "Hiragana",
          "Hluw": "Anatolian Hieroglyphs",
          "Hmng": "Pahawh Hmong",
          "Hmnp": "Nyiakeng Puachue Hmong",
          "Hrkt": "Japanese syllabaries",
          "Hung": "Old Hungarian",
          "Inds": "Indus",
          "Ital": "Old Italic",
          "Jamo": "Jamo",
          "Java": "Javanese",
          "Jpan": "Japanese",
          "Jurc": "Jurchen",
          "Kali": "Kayah Li",
          "Kana": "Katakana",
          "Khar": "Kharoshthi",
          "Khmr": "Khmer",
          "Khoj": "Khojki",
          "Kits": "Khitan small script",
          "Knda": "Kannada",
          "Kore": "Korean",
          "Kpel": "Kpelle",
          "Kthi": "Kaithi",
          "Lana": "Lanna",
          "Laoo": "Lao",
          "Latf": "Fraktur Latin",
          "Latg": "Gaelic Latin",
          "Latn": "Latin",
          "Lepc": "Lepcha",
          "Limb": "Limbu",
          "Lina": "Linear A",
          "Linb": "Linear B",
          "Lisu": "Fraser",
          "Loma": "Loma",
          "Lyci": "Lycian",
          "Lydi": "Lydian",
          "Mahj": "Mahajani",
          "Maka": "Makasar",
          "Mand": "Mandaean",
          "Mani": "Manichaean",
          "Marc": "Marchen",
          "Maya": "Mayan hieroglyphs",
          "Medf": "Medefaidrin",
          "Mend": "Mende",
          "Merc": "Meroitic Cursive",
          "Mero": "Meroitic",
          "Mlym": "Malayalam",
          "Modi": "Modi",
          "Mong": "Mongolian",
          "Moon": "Moon",
          "Mroo": "Mro",
          "Mtei": "Meitei Mayek",
          "Mult": "Multani",
          "Mymr": "Myanmar",
          "Nand": "Nandinagari",
          "Narb": "Old North Arabian",
          "Nbat": "Nabataean",
          "Newa": "Newa",
          "Nkgb": "Naxi Geba",
          "Nkoo": "N’Ko",
          "Nshu": "Nüshu",
          "Ogam": "Ogham",
          "Olck": "Ol Chiki",
          "Orkh": "Orkhon",
          "Orya": "Odia",
          "Osge": "Osage",
          "Osma": "Osmanya",
          "Ougr": "Old Uyghur",
          "Palm": "Palmyrene",
          "Pauc": "Pau Cin Hau",
          "Perm": "Old Permic",
          "Phag": "Phags-pa",
          "Phli": "Inscriptional Pahlavi",
          "Phlp": "Psalter Pahlavi",
          "Phlv": "Book Pahlavi",
          "Phnx": "Phoenician",
          "Plrd": "Pollard Phonetic",
          "Prti": "Inscriptional Parthian",
          "Qaag": "Zawgyi",
          "Rjng": "Rejang",
          "Rohg": "Hanifi",
          "Roro": "Rongorongo",
          "Runr": "Runic",
          "Samr": "Samaritan",
          "Sara": "Sarati",
          "Sarb": "Old South Arabian",
          "Saur": "Saurashtra",
          "Sgnw": "SignWriting",
          "Shaw": "Shavian",
          "Shrd": "Sharada",
          "Sidd": "Siddham",
          "Sind": "Khudawadi",
          "Sinh": "Sinhala",
          "Sogd": "Sogdian",
          "Sogo": "Old Sogdian",
          "Sora": "Sora Sompeng",
          "Soyo": "Soyombo",
          "Sund": "Sundanese",
          "Sylo": "Syloti Nagri",
          "Syrc": "Syriac",
          "Syre": "Estrangelo Syriac",
          "Syrj": "Western Syriac",
          "Syrn": "Eastern Syriac",
          "Tagb": "Tagbanwa",
          "Takr": "Takri",
          "Tale": "Tai Le",
          "Talu": "New Tai Lue",
          "Taml": "Tamil",
          "Tang": "Tangut",
          "Tavt": "Tai Viet",
          "Telu": "Telugu",
          "Teng": "Tengwar",
          "Tfng": "Tifinagh",
          "Tglg": "Tagalog",
          "Thaa": "Thaana",
          "Thai": "Thai",
          "Tibt": "Tibetan",
          "Tirh": "Tirhuta",
          "Tnsa": "Tangsa",
          "Toto": "Toto",
          "Ugar": "Ugaritic",
          "Vaii": "Vai",
          "Visp": "Visible Speech",
          "Vith": "Vithkuqi",
          "Wara": "Varang Kshiti",
          "Wcho": "Wancho",
          "Wole": "Woleai",
          "Xpeo": "Old Persian",
          "Xsux": "Sumero-Akkadian Cuneiform",
          "Yezi": "Yezidi",
          "Yiii": "Yi",
          "Zanb": "Zanabazar Square",
          "Zinh": "Inherited",
          "Zmth": "Mathematical Notation",
          "Zsye": "Emoji",
          "Zsym": "Symbols",
          "Zxxx": "Unwritten",
          "Zyyy": "Common",
          "Zzzz": "Unknown Script"
        },
        "narrow": {
        },
        "short": {
          "Cans": "UCAS",
          "Xsux": "S-A Cuneiform"
        }
      }
    }
  },
  "locale": "en"
})
}